import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const AdminPage = React.lazy(() => import("pages/AdminPage"));
const AdminSignInPage = React.lazy(() => import("pages/AdminSignInPage"));
const SignInPage = React.lazy(() => import("pages/SignInPage"));
const MainPage = React.lazy(() => import("pages/MainPage"));
const Preloader = React.lazy(() => import("components/Preloader"));

export default function App(): JSX.Element {
    return (
        <Suspense fallback={<h1>Loading...</h1>}>
            <Preloader />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SignInPage />} />
                    <Route path="/signin" element={<SignInPage />} />
                    <Route path="/adminsignin" element={<AdminSignInPage />} />
                    <Route path="/main" element={<MainPage />} />
                    <Route path="/admin" element={<AdminPage />} />
                </Routes>
            </BrowserRouter>
        </Suspense>
    );
}
